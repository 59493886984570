.NavBar{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position:fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(5px);
  width: 100%; /* Assuming you want the container to span the full width */
  height: 60px;
  padding: 5px;
  font-size:18px;
}
.NavBarButton{
  margin: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  color:white;
  height: 30px;
}
.NavBarButton:hover,
.NavBarLink:hover{
  transform:translate(2px,2px);
  color:white
}
.NavBarButtonContainer {
  display:flex;
  flex-direction: row;
  align-items: center;
}
.NavBarLink {
  margin-right: 20px;
  text-decoration: none;
  color:white;
}