.ProfileBox{
  border-radius: 5px;
  padding: 20px;
  margin-right: 50px;
  margin-left: 50px;
  background-color: #2a4761;
  font-size:30px;
}
.ProfileBox:hover{
  transform:translate(2px,2px)
}
.ProfileButtonContainer {
  display: flex;
  justify-content: space-between;
}
.ProfileBoxButtons{
  display: flex;
  margin: 2px;
  border: none;
  background: transparent;
  color:white;
  font-size:20px;
}
.ProfileBoxButtons:hover{
color:black;
text-decoration: underline;
}
.ProfileImage{
  border:none;
  border-radius: 5px;
  height: 10rem;
  width: 10rem;
  box-shadow: rgba(255, 255, 255, 0.39) 0px 22px 70px 4px;
}