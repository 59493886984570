
.featuresContainer {
// border: 2px solid cyan;
width: 80%;
display: flex;
flex-direction: column;
}
.feature {
    // border: 2px solid magenta;
    display: flex;
    align-items: center;
    justify-content:space-between;
    height: auto;
    padding: 10px;
    margin: 10px;
}
.FeaturesGif {
    width: 900px;
    transition: 0.5s;
    border-radius: 5px;
}
.FeaturesGif:hover {
    width: 900px;
    border:1px solid;
}
.featureText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: normal;
    margin: 21px;
}
.featurDescription {
    font-size: medium;
    font-weight: lighter;
}

