@import 'NavBar';
@import 'Frequentlyaq.scss';
@import 'ProfileBox';
@import 'Features';
// body{
//   background-color:black
// }
html { 
  background: url(../assets/app-background-image.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: medium;
  color:white;
}
.Profiles{
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
}
.footer{
  // background-color: rgba(128, 128, 128, 0.61);
  color:white;
  font-size:20px;
  // width:100%
}
.KN-desc {
  width: 60%;
  margin: 20px;
  font-weight: lighter;
}
.ourLogo {
  width: 200px;
  height: 200px;
  margin-top: 100px;
}