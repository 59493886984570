.FAQ{
  display: flex;
 justify-content: center;
  padding: 10px;
  width: 80%;
  // border: 2px solid magenta;
}
.FaqQuestion{
  // font-size:25px
}
.FaqAnswer {
  max-height:0;
  // font-size:25px;
  overflow:hidden;
  transition:max-height 0.3s ease-in-out;
}

.FaqQuestion:hover + .FaqAnswer {
  max-height:100px;
  color: #1DB954;
}
.FAQColumn {
  // border: 2px solid cyan;
  max-width: 35%;
  padding-right: 30px;
  text-align: start;
}
.barQ {
  border: 2px solid grey;
  min-height: 100%;
  margin-right: 10px;
}
.barA {
  border: 2px solid #1DB954;
  min-height: 100%;
  margin-right: 10px;
}
.FaqQuestion {
display: flex;
margin-top: 5px;
}
.FaqAnswer {
  display: flex;
}